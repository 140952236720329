import React, { useState, useEffect } from "react";
import Header from "../../Layout/menu/Header";
import DataTable from "react-data-table-component";
import { Link, useNavigate, useParams } from "react-router-dom";

import { HiDotsHorizontal } from "react-icons/hi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 
import { FaArrowLeft, FaArrowRight, FaBusinessTime } from "react-icons/fa"; 
import LoadingIndicator from "../../components/LoadingIndicator";
import api from "../Auth/axiosSetup";

function UserActivitiesDetail() {
  const apiHost = process.env.REACT_APP_API_HOST;
  const [loading, setLoading] = useState(true);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const { id , } = useParams();
  const [error, setError] = useState(null);
  const [forms, setForms] = useState([]);
  const [summary, setSummary] = useState(null);
  const [date, setDate] = useState(new Date()); 
  const navigate = useNavigate();


  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  useEffect(() => {
    const fetchUserActivities = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/useractivities/${id}`,
          { params: { date: formatDate(date) } } // Pass formatted date as parameter
        );
        setForms(response.data);
        setError("");

      } catch (error) {
        console.error(error);
        setForms([]);

        setError("Failed to fetch data from the API.");
      } finally {
        
        setLoading(false);
      }
    };

    fetchUserActivities();
  }, [id, date]); 

  useEffect(() => {
    const fetchSummary = async () => {
      setSummaryLoading(true);
      try {
        const response = await api.get(`/daily-summary/${id}`, 
          { params: { date: formatDate(date) } } 
        );
        setSummary(response.data);
      } catch (error) {
        console.error(error);
        setSummary(null);
      } finally {
        setSummaryLoading(false);
      }
    };

    fetchSummary();
  }, [id, date]); 

  const handlePreviousDate = () => {
    setDate((prevDate) => new Date(prevDate.setDate(prevDate.getDate() - 1)));
  };

  const handleNextDate = () => {
    setDate((prevDate) => new Date(prevDate.setDate(prevDate.getDate() + 1)));
  };
  const getValeurReferentielHierarchy = (valeurReferentiel) => {
    const values = [];
    let current = valeurReferentiel;
  
    // Traverse the hierarchy
    while (current) {
      values.unshift(current.valeur); // Add value to the start of the array
      current = current.parent; // Move to the parent
    }
  
    return values.join(' -> '); // Concatenate values with " -> "
  };
  const columns = [
   
    {
      name: "Activité",
      selector: (row) => {
        return row.activity.Temps_de_travail === "Oui" ? (
          <>
            {row.activity_name} <FaBusinessTime />
          </>
        ) : (
          row.activity_name
        );
      }
    },
    {
      name: "Intervention liée",
      selector: (row) => row.intervention_id ? <Link to={`/interventiondetail/${row.intervention_id}`}>{row.intervention_id}</Link> : <HiDotsHorizontal />,
      sortable: true,
    },
    {
      name: "Début",
      selector: (row) => row.debut 
        ? new Date(row.debut).toLocaleString('fr-FR', { 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit', 
            hour12: false 
          }) 
        : "N/A",
    },
    {
      name: "Fin",
      selector: (row) => row.Fin 
        ? new Date(row.Fin).toLocaleString('fr-FR', { 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit', 
            hour12: false 
          }) 
        : "N/A",
    },
    {
      name: "Durée totale",
      selector: (row) => {
        if (!row.debut || !row.Fin) {
          return "Non spécifié"; 
        }
  
        const debutDate = new Date(row.debut);
        const finDate = new Date(row.Fin);
  
        const durationMs = finDate - debutDate;
  
        if (durationMs < 0) {
          return "Invalid duration"; 
        }
  
        const hours = Math.floor(durationMs / (1000 * 60 * 60));
        const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
  
        return `${hours}h ${minutes}m`; 
      }
    },
    {
      name: "Référentiel",
      selector: (row) =>
        row.intervention && row.intervention.valeur_referentiel
          ? getValeurReferentielHierarchy(row.intervention.valeur_referentiel)
          : "N/A",
    },
    
  ];

  return (
    <div className="d-flex flex-column flex-root">
      <div className="page d-flex flex-row flex-column-fluid">
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="Customcontainer bg-white">
            <div className="row bg-white" style={{ flex: 1, minHeight: "91.1vh" }}>
              <div
                className="col-2 p-5"
                style={{ borderRight: "1px solid rgba(58,56,56,.1)" }}
              >
                <div className="d-grid gap-2 col-6 mx-auto mt-3">
                  <button
                    onClick={() => navigate(-1)}
                    className="btn btn-secondary"
                  >
                    <FaArrowLeft />

                    Retour
                  </button>
                </div>
              </div>
              <div className="col-10">
                <h1 className="d-flex fw-bold my-1 fs-3 p-4" style={{ fontSize: "1.5em", fontWeight: "400" }}>
                  Suivi des activités (Activités)
                </h1>
                <div className="d-flex align-items-center mb-4 bg-secondary justify-content-center">
                  <button className="btn btn-secondary me-2" onClick={handlePreviousDate}>
                    <FaArrowLeft />
                  </button>
                  <DatePicker
                    selected={date}
                    onChange={(newDate) => setDate(newDate)} // Update date state when a date is selected
                    dateFormat="yyyy-MM-dd" // Display format in date picker
                    className="form-control"
                  />
                  <button className="btn btn-secondary ms-2" onClick={handleNextDate}>
                    <FaArrowRight />
                  </button>
                </div>
                <div className="card-body pt-0">
                  {loading ? (
                    <LoadingIndicator />
                  ) : (
                    <>
                    
                        <DataTable
                          columns={columns}
                          data={forms}
                          pagination
                          highlightOnHover
                          paginationPerPage={10}
                          noDataComponent="Aucune activité prévue ce jour pour ce technicien."
                          customStyles={{
                            headCells: {
                              style: {
                                fontSize: "14px",
                                fontWeight: "bold",
                                textTransform: "uppercase",
                                color: "black",
                              },
                            },
                          }}
                        />
                      
                    </>
                  )}
                </div>
                {/* Résumé quotidien section */}
                <div className="summary-section mt-4">
                  {summaryLoading ? (
                    <LoadingIndicator />
                  ) : summary &&   (
                    <>
                    <h2 style={{ fontSize: '1.5rem', marginBottom: '1rem', textAlign: 'center', color: '#333' }}>
                      Résumé quotidien
                    </h2>
                    <div style={{ 
                      backgroundColor: '#f8f9fa', 
                      padding: '1.5rem', 
                      borderRadius: '0.5rem', 
                      boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)' 
                    }}>
                      <p style={{ margin: '0.5rem 0', color: '#555' }}>
                        <strong style={{ color: '#000' }}>Début de la journée:</strong> {summary.start || 'N/A'}
                      </p>
                      <p style={{ margin: '0.5rem 0', color: '#555' }}>
                        <strong style={{ color: '#000' }}>Fin de la journée:</strong> {summary.end || 'N/A'}
                      </p>
                      <p style={{ margin: '0.5rem 0', color: '#555' }}>
                        <strong style={{ color: '#000' }}>Temps de travail:</strong> {summary.workTime || 'N/A'}
                      </p>
                      <p style={{ margin: '0.5rem 0', color: '#555' }}>
                        <strong style={{ color: '#000' }}>Hors temps de travail:</strong> {summary.outOfWorkTime || 'N/A'}
                      </p>
                      <p style={{ margin: '0.5rem 0', color: '#555' }}>
                        <strong style={{ color: '#000' }}>Amplitude:</strong> {summary.amplitude || 'N/A'}
                      </p>
                    </div>

                    </>
                      
                  ) }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserActivitiesDetail;
