import React, { useState ,useEffect } from "react";

import { IoManSharp } from "react-icons/io5";
import {  Link, useNavigate } from "react-router-dom";
import { FaListUl } from "react-icons/fa";
import api from "../../Auth/axiosSetup";
import LoadingIndicator from "../../../components/LoadingIndicator";

function TechniciensTab() {
  const apiHost = process.env.REACT_APP_API_HOST;

  const [users, setUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true); 
  const navigate = useNavigate();
  useEffect(() => {
	const fetchUsers = async () => {
		try {
		  const response = await api.get(`/technicians`);
		  setUsers(response.data); 
		  setIsLoadingUsers(false); 
		} catch (error) {
		  console.error('Error fetching users:', error);
		  setIsLoadingUsers(false); 
		}
	  };

	fetchUsers();
  }, []);
  return (
 
	<div
	className="tab-pane fade"
	id="kt_table_widget_5_tab_2"
  >
	{/*begin::Table container*/}
	<div className="table-responsive">
	  {/*begin::Table*/}
	  <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
		{/*begin::Table head*/}
		<thead>
		  <tr className="border-0">
			<th className="p-0 w-50px" />
			<th className="p-0 min-w-150px" />
			<th className="p-0 min-w-140px" />
			<th className="p-0 min-w-110px" />
			<th className="p-0 min-w-50px" />
		  </tr>
		</thead>
		{/*end::Table head*/}
		{/*begin::Table body*/}
		<tbody>
				{isLoadingUsers ? (
				<tr>
                <td colSpan="5" className="text-center"><LoadingIndicator /></td>
              </tr>
			) : (
				users.map(user => (
				<tr>
					<td>
					<div className="symbol symbol-45px me-2">
						<span className="symbol-label">
						<IoManSharp  width={91}/>
						</span>
					</div>
					</td>
					<td>
					<a
						href="#"
						className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6"
					>
						 {user.nom} {user.prenom}
					</a>
					<span className="text-muted fw-semibold d-block">
						{user.email}
					</span>
					</td>
					<td className="text-end text-muted fw-bold">
					{user.ville} - {user.adresse}
					</td>
					<td className="text-end">
					{user.last_user_activity  && !user.last_user_activity.Fin ? (
                                <Link to={user.last_user_activity?.intervention && `interventiondetail/${user.last_user_activity?.intervention?.id}`}
								className="badge"
								style={{ backgroundColor: user.last_user_activity.activity.Couleur || '#f0f0f0', color: '#fff' }} // Assurez-vous que le texte est lisible
							>
								{user.last_user_activity.activity.Nom}
							</Link>
                            ) : (
                                <span className="badge badge-light-secondary">Aucune activité</span>
                            )}
					</td>
					<td className="text-end">
					<Link 
						to={`/planing/${user.id}`}
						className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
					>
						<FaListUl />

					</Link>
					</td>
				</tr>
						))
					)}
		   
		   
		  
	
		</tbody>
		{/*end::Table body*/}
	  </table>
	</div>
	{/*end::Table*/}
  </div>
  );
}

export default TechniciensTab;
