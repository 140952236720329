import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import "./intervention.css";
import ImageGallery from 'react-image-gallery'; // Import ImageGallery component
import 'react-image-gallery/styles/css/image-gallery.css'; // Import ImageGallery styles
import Footer from '../../Layout/menu/Footer';
import Header from '../../Layout/menu/Header';
import { CiCircleMinus, CiCirclePlus, CiImageOn } from 'react-icons/ci';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { format } from 'date-fns';
import Sidebar from 'react-sidebar';
import { FaArrowLeft, FaCheck, FaClipboardList, FaEdit, FaSpinner } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import LoadingIndicator from '../../components/LoadingIndicator';
import { useNavigate } from "react-router-dom";

import api from '../Auth/axiosSetup';
import { VscCheck, VscClose } from 'react-icons/vsc';
function InterventionDetail() {
    const { id } = useParams();
    const apiHost = process.env.REACT_APP_API_HOST;
    const [intervention, setIntervention] = useState([]);
    const [loading, setLoading] = useState(true);
    const ASSETSURL = process.env.REACT_APP_BACKEND_ASSETS;
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageGalleryImages, setImageGalleryImages] = useState([]);
    const [showGallery, setShowGallery] = useState(false);
    const [isOpen, setIsOpen] = useState(false); // State for opening/closing the popup
    const [selectedParent, setSelectedParent] = useState(null); // Store the selected parent detail
    const [interventionFields, setInterventionFields] = useState([]);
    const [logs, setLogs] = useState([]);
    const [editFieldLoading, setEditFieldLoading] = useState(false); // Renamed loading state
    const [expandedLogIndex, setExpandedLogIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

const [selectedFieldLogs, setSelectedFieldLogs] = useState([]);

    const toggleDetails = (index) => {
      setExpandedLogIndex(expandedLogIndex === index ? null : index);
    };
    const handleShowLogs = async (fieldId) => {
      try {
        const response = await api.get(`/interventions/${id}/fields/${fieldId}/logs`);
       const data = response.data;
        setSelectedFieldLogs(data);
        setIsModalOpen(true);
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
      setSelectedFieldLogs([]);
    };
    const columns = [
      {
        name: 'Description',
        sortable: true,
        cell: row => {
            const oldValue = row.properties.old_value ?? '';
            const newValue = row.properties.new_value ?? ''; 
            return `${oldValue} ➔ ${newValue}`;
        },
    }
    ,
      {
        name: 'Date',
        selector: 'created_at',
        sortable: true,
        cell: row => format(new Date(row.created_at), 'dd/MM/yyyy HH:mm'),
      },
      {
        name: 'Responsable',
        selector: 'causer.nom',
        sortable: true,
        cell: row => row.causer ? row.causer.nom : 'Inconnu',
      },
      
    ];
    useEffect(() => {
       
        api.get(`/interventionsshowFront/${id}`)
            .then(data => {
                console.log('Intervention Detail API Response:', data.data);
                setIntervention(data.data);
                setInterventionFields(data.data.intervention_fields.map(field => ({ ...field, editing: false })));

                setLoading(false);
                // Construct the images array
                const images = data.data.intervention_fields
                    .filter(field => field.field.field_type_id === 2 && field.value)
                    .map(field => ({
                        original: ASSETSURL + field.value,
                        thumbnail: ASSETSURL + field.value,
                        description: field.field.name // Assuming field.name is the title of the image
                    }));
                // Set images array
                console.log(images);
                setImageGalleryImages(images);

            })
            .catch(error => {
                console.error('Error fetching intervention detail:', error);
                setLoading(false);
            });
    },  [apiHost, id]);
    useEffect(() => {
      api.get(`/interventions/${id}/logs`)
          .then(data => {
              setLogs(data.data); 
          })
          .catch(error => {
              console.error('Erreur lors de la récupération des logs:', error);
          });
  }, [apiHost, id]);
    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowModal(true);
    };
    const handleValidate = async () => {
      try {
        const response =  await api.post(`/interventions/${intervention.id}/validate`);
        setIntervention((prevIntervention) => ({
          ...prevIntervention,
          ...response.data.intervention, 
        }));

      } catch (error) {
        console.error("Error validating intervention:", error);
      }
    };
  
    const handleDevalidate = async () => {
      try {
        const response =  await api.post(`/interventions/${intervention.id}/devalidate`);
        setIntervention((prevIntervention) => ({
          ...prevIntervention,
          ...response.data.intervention, 
        }));
            } catch (error) {
        console.error("Error devalidating intervention:", error);
      }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedImage(null);
    };
    const toggleGallery = () => {
        setShowGallery(!showGallery);
      };

    // if (loading) {
    //     return <div>Loading CCCC...</div>;
    // }

    const getParentHierarchy = (valeurReferentiel) => {
      const hierarchy = [];
      let current = valeurReferentiel;

      // Include the current valeur_referentiel itself
      hierarchy.push(current);

      while (current.parent) {
          current = current.parent;
          hierarchy.push(current);
      }

      return hierarchy.reverse(); // Reverse to display from root to leaf
  };

  const parentHierarchy = intervention.valeur_referentiel ? getParentHierarchy(intervention.valeur_referentiel) : [];
  const sidebarContent = (
    <div style={{ fontFamily: "'Arial', sans-serif", color: "#333" }}>
      <h2 style={{ color: "#4CAF50", marginBottom: "10px" }}>Détails Référentiel</h2>
      {loading ? (
        <p style={{ textAlign: "center" }}>Chargement...</p>
      ) : selectedParent ? (
        <div
  style={{
    background: "#f9f9f9",
    padding: "15px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  }}
>
  {[
    { label: 'Nom', value: selectedParent.valeur },
    { label: 'Référentiel parent', value: selectedParent?.parent?.valeur || '-' },
    { label: 'Agence', value: 'Agence Principale' },
    { label: 'Code', value: selectedParent.code || '-' },
    { label: 'Nombre de sites', value: selectedParent.nombre_sites || '-' },
    { label: 'Nouveau', value: selectedParent.nouveau || '-' },
    { label: 'Adresse', value: selectedParent.adresse || '-' },
    { label: 'Code postal', value: selectedParent.code_postal || '-' },
    { label: 'Ville', value: selectedParent.ville || '-' },
    { label: 'Pays', value: selectedParent.pays || '-' },
    { label: 'Coordonnées', value: selectedParent.coordonnees || '-' },
    { label: 'Contact', value: selectedParent.contact || '-' },
    { label: 'Description', value: selectedParent.description || '-' },
  ].map((item, index) => (
    <div key={index} style={{ display: "flex", justifyContent: "space-between", padding: "5px 0" }}>
      <span style={{ fontWeight: "bold", flex: 1 }}>{item.label}</span>
      <span style={{ flex: 1, textAlign: "right" }}>{item.value}</span>
    </div>
  ))}
</div>

      ) : (
        <p style={{ textAlign: "center" }}>Aucune donnée disponible</p>
      )}
    </div>
  );
  
  // Function to handle field value change
  const handleFieldChange = (fieldId, newValue) => {
    setInterventionFields(prevFields =>
        prevFields.map(field =>
            field.id === fieldId ? { ...field, value: newValue } : field
        )
    );
};

// Function to toggle the edit mode
const handleEditToggle = (fieldId) => {
    setInterventionFields(prevFields =>
        prevFields.map(field =>
            field.id === fieldId ? { ...field, editing: !field.editing } : field
        )
    );
};

// Function to save changes and log edits
const handleSaveChanges = (fieldId) => {
  const fieldToSave = interventionFields.find(field => field.id === fieldId);
  setEditFieldLoading(true);  

  api.put(`/intervention-fields/${fieldToSave.id}`, {
      value: fieldToSave.value 
  })
  .then((data) => {
      handleEditToggle(fieldId); 
      setEditFieldLoading(false); 
    })
  .catch(error => {
      console.error('Error saving changes:', error);
      setEditFieldLoading(false); 
  });
};

  // Function to toggle the side popup
  const togglePopup = (parent) => {
    console.log("parent here =>",parent);
    setSelectedParent(parent);
    setIsOpen(true); // Open popup
  };
    return (<>
             <div>

<div className="d-flex flex-column flex-root">
  {/* {/*begin::Page */}
  <div className="page d-flex flex-row flex-column-fluid">
    {/*begin::Wrapper */}
    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
      {/*begin::Header */}

<Header/>

      {/*end::Header */}
      {/*begin::Toolbar */}
      <div className="toolbar py-5 pb-lg-15" id="kt_toolbar">
        {/*begin::Container */}
        <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
        <button
      type="button"
      className="btn btn-secondary me-5"
      onClick={() => navigate(-1)} // Navigate to the previous page
    >
      <FaArrowLeft />

      Retour
    </button>        {isOpen && (
  <Sidebar
    sidebar={sidebarContent}
    open={isOpen}
    onSetOpen={setIsOpen}
    pullRight={true}
    styles={{
      sidebar: {
        background: "#ffffff",
        zIndex: 99999,
        width: "400px",
        padding: "20px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      },
    }}
  >
    <button 
      onClick={() => setIsOpen(true)} 
      style={{
        backgroundColor: "#4CAF50", 
        color: "white", 
        border: "none", 
        padding: "10px 15px", 
        cursor: "pointer", 
        borderRadius: "5px",
        fontSize: "16px",
      }}
    >
      Open Sidebar
    </button>
  </Sidebar>
)}


       
        </div>
        {/*end::Container */}
      </div>
      {/*end::Toolbar */}
      {/*begin::Container */}
      
      <div
  id="kt_content_container"
  className="d-flex flex-column-fluid align-items-start  container-xxl "
>
  
  {/*begin::Post*/}
  <div className="content flex-row-fluid" id="kt_content">
    {/*begin::Layout*/}
    { loading ? <LoadingIndicator /> : <div className="d-flex flex-column flex-lg-row">
      {/*begin::Content*/}
      <div className="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
        {/*begin::Card*/}
        <div className="card card-flush pt-3 mb-5 mb-xl-10">
          {/*begin::Card header*/}
          <div className="card-header">
            {/*begin::Card title*/}
            <div className="card-title">
              <h2 className="fw-bold">Detail d'intervention</h2>
            </div>
            {/*begin::Card title*/}
            
          </div>
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="card-body pt-3">
            {/*begin::Section*/}
            <div className="mb-10">
              {/*begin::Title*/}
              <h5 className="mb-4">Information generale:</h5>
              {/*end::Title*/}
              {/*begin::Details*/}
              <div className="d-flex flex-wrap py-5">
                {/*begin::Row*/}
                <div className="flex-equal me-5">
                  {/*begin::Details*/}
                  <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0">
                    {/*begin::Row*/}
                    <tbody>
                      <tr>
                        <td className="text-gray-500 min-w-175px w-175px">
                          Agence:
                        </td>
                        <td className="text-gray-800 min-w-200px">
                         
                        </td>
                      </tr>
                      {/*end::Row*/}
                      {/*begin::Row*/}
                      <tr>
                        <td className="text-gray-500">Date de planification :</td>
                        <td className="text-gray-800">
                          {intervention.created_at ? format(new Date(intervention.created_at), "yyyy-MM-dd HH:mm:ss") : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-gray-500">Date fin prévue :</td>
                        <td className="text-gray-800">
                          {intervention.datefin ? format(new Date(intervention.datefin), "yyyy-MM-dd HH:mm:ss") : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-gray-500">Date Validation :</td>
                        <td className="text-gray-800">
                          {intervention?.validated_at ? format(new Date(intervention?.validated_at), "yyyy-MM-dd HH:mm:ss") : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-gray-500">Date Communiquée :</td>
                        <td className="text-gray-800">
                          {intervention.downloaded_at ? format(new Date(intervention.downloaded_at), "yyyy-MM-dd HH:mm:ss") : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-gray-500">Date debut :</td>
                        <td className="text-gray-800">
                          {intervention.started_at ? format(new Date(intervention.started_at), "yyyy-MM-dd HH:mm:ss") : ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-gray-500">Date Sold :</td>
                        <td className="text-gray-800">
                          {intervention.sold_at ? format(new Date(intervention.sold_at), "yyyy-MM-dd HH:mm:ss") : ""}
                        </td>
                      </tr>
                     
                      
                      {/*end::Row*/}
                      {/*begin::Row*/}
                      <tr>
                        <td className="text-gray-500">A faire avant	:</td>
                        <td className="text-gray-800">
                        {intervention.afterDate}
                        </td>
                      </tr>
                      {/*end::Row*/}
                      {/*begin::Row*/}
                      <tr>
                        <td className="text-gray-500">À faire apres	:</td>
                        <td className="text-gray-800">{intervention.beforeDate} </td>
                      </tr>
                      {/*end::Row*/}
                    </tbody>
                  </table>
                  {/*end::Details*/}
                </div>
                {/*end::Row*/}
                {/*begin::Row*/}
                <div className="flex-equal">
                  {/*begin::Details*/}
                  <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0">
                    {/*begin::Row*/}
                    <tbody>
                    <tr>
                        <td className="text-gray-500 min-w-175px w-175px">
                          Reference :
                        </td>
                        <td className="text-gray-800 min-w-200px">
                        #{intervention.id}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-gray-500 min-w-175px w-175px">
                          Drapeaux:
                        </td>
                        <td className="text-gray-800 min-w-200px">
                        {intervention.flag && (
                                                                                <span className="badge me-2 text-white" style={{ backgroundColor: intervention.flag.color }}>
                                                                                    {intervention.flag.name}
                                                                                </span>
                                                                            )}
                        </td>
                      </tr>
                      {/*end::Row*/}
                      {/*begin::Row*/}
                      <tr>
                        <td className="text-gray-500">Description:</td>
                        <td className="text-gray-800">{intervention.description}
                        </td>
                      </tr>
                      {/*end::Row*/}
                      
                      {/*end::Row*/}
                      {/*begin::Row*/}
                      <tr>
                        <td className="text-gray-500">Type d'intervention	:</td>
                        <td className="text-gray-800">{intervention.intervention_type?.name}</td>
                      </tr>
                      {/*end::Row*/}
                      {/*begin::Row*/}
                      <tr>
                        <td className="text-gray-500">Formulaire Lié	:</td>
                        <td className="text-gray-800">{intervention.form.name}</td>
                      </tr>
                      {/*end::Row*/}
                    </tbody>
                  </table>
                  {/*end::Details*/}
                </div>
                {/*end::Row*/}
              </div>
              {/*end::Row*/}
            </div>
            {/*end::Section*/}
            {/*begin::Section*/}
            <div className="mb-0">
              {/*begin::Title*/}
              <h5 className="mb-4">Technicien Participants:</h5>
              {/*end::Title*/}
              {/*begin::Product table*/}
              <div className="table-responsive">
                {/*begin::Table*/}
                <table className="table align-middle table-row-dashed fs-6 gy-4 mb-0">
                  {/*begin::Table head*/}
                  <thead>
                    {/*begin::Table row*/}
                    <tr className="border-bottom border-gray-200 text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                      <th className="min-w-150px">Nom</th>
                      <th className="min-w-125px">Email</th>
                      
                    </tr>
                    {/*end::Table row*/}
                  </thead>
                  {/*end::Table head*/}
                  {/*begin::Table body*/}
                  <tbody className="fw-semibold text-gray-800">
                    <tr>
                      <td>
                        <label className="w-150px">{intervention.user.nom}</label>
                        
                      </td>
                      <td>
                        <span className="badge badge-light-danger">
                        {intervention.user.email}
                        </span>
                      </td>
                      
                    </tr>
                    
                  </tbody>
                  {/*end::Table body*/}
                </table>
                {/*end::Table*/}
              </div>
              {/*end::Product table*/}
            </div>
            {/*end::Section*/}
          </div>
          {/*end::Card body*/}
        </div>
        {/*end::Card*/}

        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
  {/*begin::Card header*/}
  <div className="card-header cursor-pointer">
    {/*begin::Card title*/}
    <div className="card-title m-0">
      <h3 className="fw-bold m-0">Formulaire ({intervention.form.name})</h3>
    </div>
    {/*end::Card title*/}
    {/*begin::Action*/}
        <div className='m-5 d-flex align-items-center'>
            
            <span className='text-gray-500 mr-2'>          {showGallery ? 'Hide Gallery' : 'Show Gallery'}
            </span>
         <Toggle
            defaultChecked={showGallery}
            onChange={toggleGallery}
            icons={false}
            about='tesst'
            />
            </div>                                                                    
    
   
    {/*end::Action*/}
  </div>
  {/*begin::Card header*/}
  {/*begin::Card body*/}
  <div className="card-body p-9">
    {/*begin::Row*/}
    {interventionFields.map(field => (
    <div key={field.id} className="row mb-7">
    {/*begin::Label*/}
    <label className="col-lg-2 fw-semibold text-muted">{field.field.name}</label>
    {/*end::Label*/}
    {/*begin::Col*/}
    <div className="col-lg-8">
    {
        (() => {
            switch (field.field.field_type_id) {
                case 1:
                case 5:
                case 6:
                case 3:
                    return (
                      <div className="input-group mb-3" key={field.id}>
                      <input
                          type="text"
                          value={field.value}
                          onChange={(e) => handleFieldChange(field.id, e.target.value)} // Handle input change
                          disabled={!field.editing} // Toggle editable state
                          className="form-control rounded-3"
                          style={{ backgroundColor: field.editing ? '#fff' : '#f7f7f7', border: '1px solid #ccc' }}
                      />
                     <span className="input-group-text" onClick={() => {
      if (editFieldLoading) return; 

      if (field.editing) {
        handleSaveChanges(field.id);
      } else {
        handleEditToggle(field.id);
      }
    }}>
      {editFieldLoading ? ( 
        <FaSpinner className="spinner" />
      ) : (
        field.editing ? <FaCheck /> : <FaEdit />
      )}
    </span><button
                        className="btn btn-outline-secondary ms-2"
                        onClick={() => handleShowLogs(field.id)}
                      >
                        <FaClipboardList /> Logs
                       </button>
                       
                  
                  </div>
                    );
                case 8:
                case 9:
                    return (
                        <div className="input-group mb-3">
                            <input 
                                type="text" 
                                value={field.value} 
                                disabled 
                                className="form-control rounded-3" 
                                style={{ backgroundColor: '#f7f7f7', border: '1px solid #ccc' }} 
                            />
                        </div>
                    );
                case 2:
                    return (
                        <>
                            {field.value && (
                                <div className="image-preview mb-3">
                                    <Zoom>
                                        <img 
                                            srcSet={ASSETSURL + field.value} 
                                            width={"50px"} 
                                            style={{ borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }} 
                                        />
                                        <CiImageOn 
                                            srcSet={ASSETSURL + field.value} 
                                            color='black'  
                                            size={25} 
                                            onClick={() => handleImageClick(ASSETSURL + field.value)} 
                                            style={{ marginLeft: '10px', cursor: 'pointer' }} 
                                        />
                                    </Zoom>
                                </div>
                            )}
                        </>
                    );
                case 10:
                    return (
                        <div className="form-check mb-3">
                            <input 
                                type="checkbox" 
                                checked={field.value} 
                                disabled 
                                className="form-check-input" 
                            />
                            <label className="form-check-label">
                                {field.label} {/* Assuming you have a label for the checkbox */}
                            </label>
                        </div>
                    );
                // Add more cases as needed
                default:
                    return null;
            }
        })()
    }
</div>

    {/*end::Col*/}
</div>
))}
<Modal show={isModalOpen} onHide={closeModal} centered     dialogClassName="modal-lg" 
>
        <Modal.Header closeButton>
          <Modal.Title>Logs pour le Champ Sélectionné</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {selectedFieldLogs.length > 0 ? (
                        <DataTable
                            columns={columns}
                            data={selectedFieldLogs}
                            noDataComponent={<div className="text-center">Aucun log disponible pour ce champ.</div>}
                            pagination
                            highlightOnHover
                            striped
                            responsive
                        />
                    ) : (
                        <div className="text-center">Ce champs n'as jamais été modifier </div>
                    )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
      {showGallery && <ImageGallery items={imageGalleryImages} />}
                                            


    {/*end::Row*/}
    
   
  </div>
  {/*end::Card body*/}
</div>
<div className="card mb-5 mb-xl-12" id="kt_profile_details_view">
  {/*begin::Card header*/}
  <div className="card-header cursor-pointer">
    {/*begin::Card title*/}
    <div className="card-title m-0">
      <h3 className="fw-bold m-0">Activités associées</h3>
    </div>
    {/*end::Card title*/}
    {/*begin::Action*/}
                                                                          
    
   
    {/*end::Action*/}
  </div>
  {/*begin::Card header*/}
  {/*begin::Card body*/}
  <div className="card-body p-2">
  {/*begin::Row*/}
  <div className="table-responsive">
    <table className="table table-striped table-bordered mt-3">
      <thead>
        <tr>
          <th>Nom de l'activité</th>
          <th>Début</th>
          <th>Fin</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {intervention.user_activities && intervention.user_activities.length > 0 ? (
          intervention.user_activities.map((activity, index) => (
            <React.Fragment key={activity.id}>
              <tr>
                <td>{activity.activity?.Nom || "Non spécifié"}  <span
                              style={{
                                display: "inline-block",
                                width: "20px",
                                height: "20px",
                                backgroundColor: activity.activity.Couleur || "#ccc",
                                borderRadius: "50%",
                              }}
                            ></span></td>
                <td>{activity.debut || "Non disponible"}</td>
                <td>{activity.Fin || "Non disponible"}</td>
                <td>
                  <button
                    className="btn btn-link"
                    onClick={() => toggleDetails(index)}
                  >
                    {expandedLogIndex === index ? <CiCircleMinus /> : <CiCirclePlus />}
                  </button>
                </td>
              </tr>

              {/* Expanded Details Row */}
              {expandedLogIndex === index && (
                <tr>
                  <td colSpan="4">
                    <div className="details-section p-3">
                      {activity.activity ? (
                        <>
                         
                          <div>
                            <strong>Temps réel:</strong> {activity.activity.Temps_réel ? "Oui" : "Non"}
                          </div>
                          <div>
                            <strong>Couleur:</strong>{" "}
                            <span
                              style={{
                                display: "inline-block",
                                width: "20px",
                                height: "20px",
                                backgroundColor: activity.activity.Couleur || "#ccc",
                                borderRadius: "50%",
                              }}
                            ></span>
                          </div>
                        </>
                      ) : (
                        <div>Aucune information additionnelle disponible.</div>
                      )}
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))
        ) : (
          <tr>
            <td colSpan="4" className="text-center">
              Aucune activité associée trouvée pour cette intervention.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
  {/*end::Row*/}
</div>

  {/*end::Card body*/}
</div>
<div className="card mb-5 mb-xl-12" id="kt_profile_details_view">
  {/*begin::Card header*/}
  <div className="card-header cursor-pointer">
    {/*begin::Card title*/}
    <div className="card-title m-0">
      <h3 className="fw-bold m-0">Historique des actions</h3>
    </div>
    {/*end::Card title*/}
    {/*begin::Action*/}
                                                                          
    
   
    {/*end::Action*/}
  </div>
  {/*begin::Card header*/}
  {/*begin::Card body*/}
  <div className="card-body p-2">
    {/*begin::Row*/}
    <div className="table-responsive">
      <table className="table table-striped table-bordered mt-3">
        <thead>
          <tr>
            <th>Description</th>
            <th>Date</th>
            <th>Responsable</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {logs && logs.length > 0 ? (
            logs.map((log, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>{log.description}</td>
                  <td>{format(new Date(log.created_at), 'dd/MM/yyyy HH:mm')}</td>
                  <td>{log.causer ? log.causer.nom : 'Inconnu'}</td>
                  <td>
                    <button
                      className="btn btn-link"
                      onClick={() => toggleDetails(index)}
                    >
                      {expandedLogIndex === index ?  <CiCircleMinus /> : <CiCirclePlus /> }
                    </button>
                  </td>
                </tr>

                {expandedLogIndex === index && (
                  <tr>
                    <td colSpan="4">
                      <div className="details-section p-3">
                        {log.properties && Object.keys(log.properties).length > 0 ? (
                          Object.entries(log.properties).map(([key, value]) => (
                            <div key={key}>
                              <strong>{key.replace(/_/g, ' ')}:</strong> {value || 'N/A'}
                            </div>
                          ))
                        ) : (
                          <div>Aucune propriété additionnelle disponible pour ce log.</div>
                        )}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                Aucun log disponible pour cette intervention.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
                                            


    {/*end::Row*/}
    
   
  </div>
  {/*end::Card body*/}
</div>



        {/*end::Card*/}
      
      </div>
      {/*end::Content*/}
      {/*begin::Sidebar*/}
      <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-300px mb-10 order-1 order-lg-2">
        {/*begin::Card*/}
        <div
          className="card card-flush mb-0"
          data-kt-sticky="true"
          data-kt-sticky-name="subscription-summary"
          data-kt-sticky-offset="{default: false, lg: '200px'}"
          data-kt-sticky-width="{lg: '250px', xl: '300px'}"
          data-kt-sticky-left="auto"
          data-kt-sticky-top="150px"
          data-kt-sticky-animation="false"
          data-kt-sticky-zindex={95}
        >
          {/*begin::Card header*/}
          <div className="card-header">
            {/*begin::Card title*/}
            <div className="card-title">
              <h2>Sommaire</h2>
            </div>
            {/*end::Card title*/}
            {/*begin::Card toolbar*/}
            <div className="card-toolbar">
              {/*begin::More options*/}
              <button
                className="btn btn-sm btn-light btn-icon"
         
              >
                <i className="ki-duotone ki-dots-square fs-3">
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                  <span className="path4" />
                </i>
              </button>
             
              {/*end::More options*/}
            </div>
            {/*end::Card toolbar*/}
          </div>
          
          {/*end::Card header*/}
          {/*begin::Card body*/}
          <div className="card-body pt-0 fs-6">
            {/*begin::Section*/}
           
            {/*begin::Seperator*/}
            <div className="separator separator-dashed mb-7" />
            {/*end::Seperator*/}
            {/*begin::Section*/}
            <div className="mb-7">
              {/*begin::Title*/}
              <h5 className="mb-4">A propos de l'intervention</h5>
              {/*end::Title*/}
              {/*begin::Details*/}
              <div className="mb-3">
                {/*begin::Price*/}
                <span className="fw-semibold text-gray-600">
                  Status : 
                </span>
                {/*end::Price*/}
                {/*begin::Plan*/}
                <span
    className={`badge ${intervention.sold_at ? 'badge-light-danger' :
                  intervention.started_at ? 'badge-light-warning' :
                  intervention.downloaded_at ? 'badge-light-info' :
                  'badge-light-success'} me-2`}
>
    {intervention.sold_at ? (
        <span>Réalisée</span>
    ) : intervention.started_at ? (
        <span>Démarrée</span>
    ) : intervention.downloaded_at ? (
        <span>Synchronisée</span>
    ) : (
        <span>Planifié</span>
    )}
</span>


                {/*end::Plan*/}
              </div>
              <div className="mb-0">
                {/*begin::Price*/}
               
                {/*end::Price*/}
                {/*begin::Plan*/}
                {}
                {intervention.sold_at && (
        intervention.validated_at ? (
          <button
            type="button"
            className="btn btn-danger me-5"
            onClick={handleDevalidate}
          >
            <VscClose /> Devalider
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-success me-5"
            onClick={handleValidate}
          >
            <VscCheck /> Valider
          </button>
        )
      )}
                


                {/*end::Plan*/}
              </div>
              {/*end::Details*/}
            </div>
            {/*end::Section*/}
          
            <div className="separator separator-dashed mb-7" />
            {/*end::Seperator*/}
            {/*begin::Section*/}
            <div className="mb-10">
              {/*begin::Title*/}
              {/*end::Title*/}
              {/*begin::Details*/}
              <div className="mb-10">
      {/* Title */}
      <h5 className="mb-4">Référentiel</h5>
      {/* Table */}
      {parentHierarchy.length > 0 ? (
        <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2">
          <tbody>
            {parentHierarchy.map((parent, index) => (
              <tr key={index} >
                <td
                  className="text-gray-800"
                  title={`Référentiel ID: ${parent.referentiel_id}\nCréé le: ${parent.created_at}`}
                  onClick={() => togglePopup(parent)}
                >
                  {parent.valeur} xx
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-gray-800">Aucun parent disponible.</p>
      )}

      {/* Side popup using react-burger-menu */}
    
    </div>

              {/*end::Details*/}
            </div>
            {/*end::Section*/}
          
          </div>
          {/*end::Card body*/}
        </div>
        
        {/*end::Card*/}{" "}
      </div>
      
      {/*end::Sidebar*/}
    </div>
   }
    
    
    {/*end::Layout*/}{" "}
  </div>
  {/*end::Post*/}
</div>

      {/*end::Container */}
      {/*begin::Footer */}
     <Footer></Footer>
      {/*end::Footer */}
    </div>
    {/*end::Wrapper */}
  </div>
  {/*end::Page */}
</div>
</div >

           </>
    );
}

export default InterventionDetail;
