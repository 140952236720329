import React, { useState, useEffect } from "react";
import Header from "../../Layout/menu/Header";
import Footer from "../../Layout/menu/Footer";
import DataTable from "react-data-table-component";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { FaEdit, FaTrash } from "react-icons/fa";
import api from "../Auth/axiosSetup";
import LoadingIndicator from "../../components/LoadingIndicator";

function Formulaires() {
  const apiHost = process.env.REACT_APP_API_HOST;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [forms, setForms] = useState([]);
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [formName, setFormName] = useState("");

  const [Searchstring, setSearchstring] = useState("");
  const [filteredForms, setFilteredForms] = useState(forms);

  const [editModal, setEditModal] = useState(false);
  const [editFormData, setEditFormData] = useState({ name: "" });
  const [editFormId, setEditFormId] = useState(null);

  const handleShowEditModal = (form) => {
    setEditFormData({ name: form.name });
    setEditFormId(form.id);
    setEditModal(true);
  };

  const handleCloseEditModal = () => {
    setEditModal(false);
    setEditFormData({});
    setEditFormId(null);
  };

  const handleEditFormNameChange = (event) => {
    setEditFormData({ ...editFormData, name: event.target.value });
  };

  const handleEditFormSubmit = async () => {
    try {
      setLoading(true);
      const response = await api.put(`${apiHost}/forms/${editFormId}`, {
        name: editFormData.name,
      });
      const updatedForm = response.data.data;
      setForms(
        forms.map((form) => (form.id === editFormId ? updatedForm : form))
      );
      handleCloseEditModal();
    } catch (error) {
      console.error("Error editing form:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setFormName("");
  };

  useEffect(() => {
    setFilteredForms(
      forms.filter((flag) =>
        flag.name.toLowerCase().includes(Searchstring.toLowerCase())
      )
    );
  }, [Searchstring, forms]);

  const handleInputChange = (e) => {
    setSearchstring(e.target.value);
  };

  const handleFormNameChange = (event) => {
    setFormName(event.target.value);
  };

  const handleFormSubmit = async () => {
    try {
      setLoading(true);
      const response = await api.post(
        '/forms',
        { name: formName },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
  
     
  
      const newForm = response.data.data; 
      handleCloseModal(); 
    } catch (error) {
      console.error('Error adding formulaire:', error.message || error);
    } finally {
      setLoading(false); 
    }
  };
  

  const handleRowClick = (row) => {
    navigate(`/editform/${row.id}`);
  };

  const handleDelete = async (row) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this form?"
    );
    if (!confirmDelete) {
      return;
    }
    try {
      await api.delete(`${apiHost}/forms/${row.id}`);
      setForms(forms.filter((item) => item.id !== row.id));
    } catch (error) {
      console.error("Failed to delete the form", error);
    }
  };

  useEffect(() => {
    api.get(`/forms`)
      .then((response) => response.data)
      .then((data) => {
        setForms(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [apiHost]);
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
    },
    {
      name: "Nom",
      selector: (row) => row.name,
    },
    {
      name: "Date Creation",
      accessor: "created_at",
      selector: (row) => {
        const createdAtDate = new Date(row.created_at);
        return createdAtDate.toLocaleDateString(); // Adjust date format as needed
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Button
            variant="link"
            onClick={() => handleShowEditModal(row)}
            style={{
              color: "#007bff",
              marginRight: "10px", // Ajoute un espace entre les boutons
            }}
          >
            <FaEdit
              style={{
                fontSize: "24px",
              }}
            />
          </Button>
          <Button
            variant="link"
            onClick={() => handleDelete(row)}
            style={{
              color: "#dc3545",
            }}
          >
            <FaTrash
              style={{
                fontSize: "24px",
              }}
            />
          </Button>
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "26px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "700",
        backgroundColor: "#fafafa ",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  return (
    <div>
      <div className="d-flex flex-column flex-root">
        {/* {/*begin::Page */}
        <div className="page d-flex flex-row flex-column-fluid">
          {/*begin::Wrapper */}
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            {/*begin::Header */}

            <Header />

            {/*end::Header */}
            <div className="Customcontainer bg-white">
              <div
                className="row bg-white"
                style={{ flex: 1, minHeight: "91.1vh" }}
              >
                <div
                  className="col-2 p-5 "
                  style={{ borderRight: "1px solid rgba(58,56,56,.1)" }}
                >
                  {/* Contenu de la colonne de gauche */}
                  <p
                    className="etech-sidebar-section"
                    style={{
                      color: "#3a3838",
                      fontWeight: "700",
                      padding: ".25em 0",
                    }}
                  >
                    Rechercher un formulaire
                  </p>
                  <div className="d-flex align-items-center position-relative my-1">
                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <input
                      type="text"
                      data-kt-subscription-table-filter="search"
                      className="form-control form-control-solid w-250px ps-12"
                      placeholder="Recherche ..."
                      onChange={handleInputChange}
                    />
                  </div>
                  <div class="d-grid gap-2 col-6 mx-auto">
                    <button
                      onClick={handleShowModal}
                      className="btn btn-primary"
                      disabled={loading}
                    >
                      Nouveau
                    </button>
                  </div>{" "}
                </div>
                <div className="col-10">
                  {/* Contenu de la colonne de droite */}
                  <h1
                    className="d-flex  fw-bold my-1 fs-3 p-4"
                    style={{ fontSize: "1.5em", fontWeight: "400" }}
                  >
                    Liste des formulaires
                  </h1>
                  <div className="card-body pt-0">
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <LoadingIndicator />
                      </div>
                    ) : (
                      <>
                        {error ? (
                          "Data not Found"
                        ) : (
                          <DataTable
                            columns={columns}
                            data={filteredForms}
                            pagination
                            highlightOnHover={true}
                            paginationPerPage={10} // Set the number of rows per page
                            onRowClicked={handleRowClick}
                            customStyles={customStyles}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <Footer /> */}

            {/*end::Footer */}
          </div>
          {/*end::Wrapper */}
        </div>
        {/*end::Page */}
      </div>

      {/* Modal for adding new form */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un formulaire</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Nom du formulaire</Form.Label>
              <Form.Control
                type="text"
                value={formName}
                onChange={handleFormNameChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Annuler
          </Button>
          <Button
            variant="primary"
            onClick={handleFormSubmit}
            disabled={!formName || loading}
          >
            {loading ? <Spinner animation="border" size="sm" /> : "Ajouter"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={editModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier un formulaire</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="editFormName">
              <Form.Label>Nom</Form.Label>
              <Form.Control
                type="text"
                value={editFormData.name}
                onChange={handleEditFormNameChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Annuler
          </Button>
          <Button
            variant="primary"
            onClick={handleEditFormSubmit}
            disabled={!editFormData.name || loading}
          >
            {loading ? (
              <Spinner
                animation="border"
                style={{ width: "1rem", height: "1rem" }}
              />
            ) : (
              "Modifier"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Formulaires;
