import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { CiSearch, CiSettings } from "react-icons/ci";
import Select from "react-select";
import api from "../../Auth/axiosSetup";
import { Tooltip } from "react-tooltip";

function Recherche({filters,setFilters}) {
  const apiHost = process.env.REACT_APP_API_HOST;

  //Population Variable
  const [forms, setForms] = useState([]);
  const [flags, setFlags] = useState([]);
  const [interventionTypes, setInterventionTypes] = useState([]);
  const [users, setUsers] = useState([]); // Nouvel état pour les utilisateurs
  const [referentiels, setReferentiels] = useState([]); // State for Referentiels

  //Population Functions 
  const fetchForms = async () => {
    try {
      const response = await api.get(`/forms`);
      const data = response.data;
      // Mapper les formulaires pour qu'ils soient compatibles avec react-select
      const options = data.data.map((form) => ({
        value: form.id, // Assumes each form has an `id` field
        label: form.name, // Utilise `name` pour l'étiquette visible
      }));
      setForms(options);
    } catch (error) {
      console.error('Error fetching forms:', error);
    }
  };
  const fetchflags = async () => {
    try {
      const response = await api.get(`/flags`);
     const data = response.data;
      // Mapper les flags pour qu'ils soient compatibles avec react-select
      const options = data.map((flag) => ({
        value: flag.id, // Assume que chaque flag a un `id`
        label: flag.name, // Utilise `name` pour l'étiquette visible
      }));
      setFlags(options); // Met à jour les options pour react-select
    } catch (error) {
      console.error('Error fetching flags:', error);
    }
  };

  const fetchInterventionTypes = async () => {
    try {
      const response = await api.get(`/intervention-types`);
     const data = response.data;
      const options = data.map((type) => ({
        value: type.id,
        label: type.name,
      }));
      setInterventionTypes(options);
    } catch (error) {
      console.error('Error fetching intervention types:', error);
    }
  };
  const fetchUsers = async () => {
    try {
      const response = await api.get(`/technicians`);
     const data = response.data;
      const options = data.map((user) => ({
        value: user.id,
        label: user.nom, // Assumes each user has a `name` field for display
      }));
      setUsers(options);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };
  const fetchReferentiels = async () => {
    try {
      const response = await api.get(`/valeur_referentiels`);
      const data = response.data;
      const options = data.map((referentiel) => ({
        value: referentiel.id,
        label: referentiel.valeur, 
      }));
      setReferentiels(options);
    } catch (error) {
      console.error("Error fetching referentiels:", error);
    }
  };

  //Fetching 
  useEffect(()=>{
    fetchForms();
    fetchflags();
    fetchInterventionTypes(); 
    fetchUsers();
    fetchReferentiels(); 


  },[]);
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
      if (name === "description" && value.startsWith("#")) {
      if (value.length > 1) {
        setFilters({ ...filters, id: value.substring(1), description: value });
      } else {
        setFilters({ ...filters, description: value, id: "" });
      }
    } else if (name === "description" && value === "") {
      setFilters({ ...filters, description: value, id: "" });
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };
  
  
  
  const handleSelectChange = (selectedOption, fieldName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [fieldName]: selectedOption ? selectedOption.value : null, // Mettre à jour le champ correspondant
    }));
  };
  const handleDateRangeChange = (dates, fieldName) => {
    const [startDate, endDate] = dates;
    setFilters({
      ...filters,
      [`${fieldName}_start`]: startDate ? format(startDate, "yyyy-MM-dd") : null,
      [`${fieldName}_end`]: endDate ? format(endDate, "yyyy-MM-dd") : null,
    });
  };
  const appointmentOptions = [
    { value: '1', label: 'Oui' }, // Oui
    { value: '0', label: 'Non' }, // Non
  ];
  const [sortBy, setSortBy] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  return (
    <>
    {/*begin::Form*/}
    <form action="#">
      {/*begin::Card*/}
      <div className="card ">
        {/*begin::Card body*/}
        <div className="card-body">
          {/*begin::Compact form*/}
          <div className="d-flex align-items-center">
            {/*begin::Input group*/}
            <div className="position-relative w-md-400px me-md-2">
    <i className="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 translate-middle ms-6">
      <CiSearch />
    </i>
    <input
      type="text"
      className="form-control form-control-solid ps-10"
      name="description"
      value={filters.description}
      onChange={handleFilterChange}
      placeholder="Recherche"
      id="my-anchor-element"
    />
    <Tooltip
  anchorSelect="#my-anchor-element"
  content="Astuce : Pour rechercher par ID, commencez par # (ex. : #123). Sans #, la recherche se fait par description."
  style={{zIndex:9999}}
/>
  </div>
            {/*end::Input group*/}
            {/*begin:Action*/}
            <div className="d-flex align-items-center">
              <button type="button" className="btn btn-primary me-5">
                Recherche
              </button>
              <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_advanced_Recherche_form"
                  aria-expanded="false"
                  aria-controls="kt_advanced_Recherche_form"
                >
                  <CiSettings /> Autre Filtres
                </button>
            </div>
            {/*end:Action*/}
            
          </div>
          {/*end::Compact form*/}
          {/*begin::Advance form*/}
         <div className="collapse" id="kt_advanced_Recherche_form">
  {/*begin::Separator*/}
  <div className="separator separator-dashed mt-9 mb-6" />
  {/*end::Separator*/}
  {/*begin::Row with your filters*/}
  <div className="row g-4 mb-4 filter-container">
  {/* Begin Filter Inputs */}
  <div className="col-lg-6">
    <div className="mb-3">
      <label className="form-label">Formulaire</label>
      <Select
        value={forms.find(option => option.value === filters.form_id) || null}
        onChange={(selectedOption) => handleSelectChange(selectedOption, 'form_id')}
        options={forms}
        placeholder="Sélectionner un Formulaire"
        isClearable
        classNamePrefix="react-select"
      />
    </div>

    <div className="mb-3 d-flex flex-column ">
  <label className="form-label me-2">Date Après</label>
  <DatePicker
                selectsRange
                startDate={filters.afterDate_start ? new Date(filters.afterDate_start) : null}
                endDate={filters.afterDate_end ? new Date(filters.afterDate_end) : null}
                onChange={(dates) => handleDateRangeChange(dates, "afterDate")}
                dateFormat="yyyy-MM-dd"
                className="form-control"
                placeholderText="Sélectionner une plage de dates"
                isClearable
              />
</div>


    <div className="mb-3">
      <label className="form-label">Texte Après</label>
      <input
        type="text"
        className="form-control"
        name="afterText"
        placeholder="Filtrer par Texte Après"
        value={filters.afterText}
        onChange={handleFilterChange}
      />
    </div>

    <div className="mb-3 d-flex flex-column ">
      <label className="form-label">Date Avant</label>
      <DatePicker
                selectsRange
                startDate={filters.beforeDate_start ? new Date(filters.beforeDate_start) : null}
                endDate={filters.beforeDate_end ? new Date(filters.beforeDate_end) : null}
                onChange={(dates) => handleDateRangeChange(dates, "beforeDate")}
                dateFormat="yyyy-MM-dd"
                className="form-control"
                placeholderText="Sélectionner une plage de dates"
                isClearable
              />
    </div>

    <div className="mb-3">
      <label className="form-label">Texte Avant</label>
      <input
        type="text"
        className="form-control"
        name="beforeText"
        placeholder="Filtrer par Texte Avant"
        value={filters.beforeText}
        onChange={handleFilterChange}
      />
    </div>
    <div className="mb-3">
      <label className="form-label">Referentiel</label>
      <Select
                    value={referentiels.find(option => option.value === filters.referentiel_id) || null}
                    onChange={(selectedOption) => handleSelectChange(selectedOption, 'referentiel_id')}
                    options={referentiels}
                    placeholder="Sélectionner un Referentiel"
                    isClearable
                    classNamePrefix="react-select"
                  />
    </div>

   
  </div>

  <div className="col-lg-6">
    

    <div className="mb-3">
      <label className="form-label">Drapeaux</label>
      <Select
        value={flags.find(option => option.value === filters.flag_id) || null}
        onChange={(selectedOption) => handleSelectChange(selectedOption, 'flag_id')}
        options={flags}
        placeholder="Sélectionner un Flag"
        isClearable
        classNamePrefix="react-select"
      />
    </div>

    <div className="mb-3">
      <label className="form-label">Rendez-vous</label>
      <Select
        value={appointmentOptions.find(option => option.value === filters.withAppointment) || null}
        onChange={(selectedOption) => handleSelectChange(selectedOption, 'withAppointment')}
        options={appointmentOptions}
        placeholder="Sélectionner Rendez-vous"
        isClearable
        classNamePrefix="react-select"
      />
    </div>

    <div className="mb-3">
      <label className="form-label">ID de l'utilisateur</label>
      <Select
        value={users.find(option => option.value === filters.user_id) || null}
        onChange={(selectedOption) => handleSelectChange(selectedOption, 'user_id')}
        options={users}
        placeholder="Sélectionner un Utilisateur"
        isClearable
        classNamePrefix="react-select"
      />
    </div>

    <div className="mb-3">
      <label className="form-label">Type d'intervention</label>
      <Select
        value={interventionTypes.find(option => option.value === filters.intervention_type_id) || null}
        onChange={(selectedOption) => handleSelectChange(selectedOption, 'intervention_type_id')}
        options={interventionTypes}
        placeholder="Sélectionner un Type d'intervention"
        isClearable
        classNamePrefix="react-select"
      />
    </div>
    <div className="mb-3 d-flex flex-column">
  <label className="form-label">Date d'intervention</label>
  <DatePicker
    selectsRange
    startDate={filters.intervention_start ? new Date(filters.intervention_start) : null}
    endDate={filters.intervention_end ? new Date(filters.intervention_end) : null}
    onChange={(dates) => handleDateRangeChange(dates, 'intervention')}
    dateFormat="yyyy-MM-dd"
    className="form-control"
    placeholderText="Filtrer par plage de dates d'intervention"
    isClearable
  />
</div>

<div className="mb-3 d-flex flex-column">
  <label className="form-label">Date de Début</label>
  <DatePicker
    selectsRange
    startDate={filters.started_start ? new Date(filters.started_start) : null}
    endDate={filters.started_end ? new Date(filters.started_end) : null}
    onChange={(dates) => handleDateRangeChange(dates, 'started')}
    dateFormat="yyyy-MM-dd"
    className="form-control"
    placeholderText="Filtrer par plage de dates de début"
    isClearable
  />
</div>

<div className="mb-3 d-flex flex-column">
  <label className="form-label">Date de Sold</label>
  <DatePicker
    selectsRange
    startDate={filters.sold_start ? new Date(filters.sold_start) : null}
    endDate={filters.sold_end ? new Date(filters.sold_end) : null}
    onChange={(dates) => handleDateRangeChange(dates, 'sold')}
    dateFormat="yyyy-MM-dd"
    className="form-control"
    placeholderText="Filtrer par plage de dates de sold"
    isClearable
  />
</div>

    <div className="mb-3">
  <label className="form-label">Type d'intervention</label>
  <Select
    value={interventionTypes.find(option => option.value === filters.intervention_type_id) || null}
    onChange={(selectedOption) => handleSelectChange(selectedOption, 'intervention_type_id')}
    options={interventionTypes}
    placeholder="Sélectionner un Type d'intervention"
    isClearable
    classNamePrefix="react-select"
  />
</div>

<div className="mb-3">
  <label className="form-label">Statut</label>
  <Select
    // value={statusOptions.find(option => option.value === filters.status) || null}
    onChange={(selectedOption) => handleSelectChange(selectedOption, 'status')}
    options={[
      { value: 'planifie', label: 'Planifié' },
      { value: 'synchronise', label: 'Synchronisée' },
      { value: 'demarre', label: 'Démarrée' },
      { value: 'realise', label: 'Réalisée' },
    ]}
    placeholder="Sélectionner un Statut"
    isClearable
    classNamePrefix="react-select"
  />
</div>

    
  </div>
  {/* End Filter Inputs */}
</div>


  {/*end::Row*/}
</div>


          {/*end::Advance form*/}
        </div>
        {/*end::Card body*/}
      </div>
      {/*end::Card*/}
    </form>
    {/*end::Form*/}
  </>
  );
}

export default Recherche;
