import React, { useState, useEffect } from "react";
import Header from "../../Layout/menu/Header";
import DataTable from "react-data-table-component";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import {  useNavigate, useParams } from "react-router-dom";
import { TbCirclesRelation } from "react-icons/tb";
import Select from "react-select";
import { FaEdit } from "react-icons/fa";

import api from "../Auth/axiosSetup";
import LoadingIndicator from "../../components/LoadingIndicator";

function ReferencielList() {
  const apiHost = process.env.REACT_APP_API_HOST;
  const { idCategory } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [forms, setForms] = useState([]);
  const navigate = useNavigate();
  const [parentReferentiels, setParentReferentiels] = useState([]);
  const [selectedParent, setSelectedParent] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const [Searchstring, setSearchstring] = useState("");
  const [filteredReferentiels, setFilteredReferentiels] = useState();
  const [filteredReferentielsParents, setFilteredReferentielsParents] = useState();

  const [showModal, setShowModal] = useState(false);
  const [formName, setFormName] = useState("");

  const [editModal, setEditModal] = useState(false);
  const [currentReferentiel, setCurrentReferentiel] = useState(null);

  useEffect(() => {
    setFilteredReferentiels(
      forms.filter((ref) =>
        ref.nom.toLowerCase().includes(Searchstring.toLowerCase())
      )
    );
  }, [Searchstring, forms]);

  const handleInputChange = (e) => {
    setSearchstring(e.target.value);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setFormName("");
    setSelectedParent(null);
  };

  const handleFormNameChange = (event) => {
    setFormName(event.target.value);
  };

  const handleShowEditModal = (referentiel) => {
    setCurrentReferentiel(referentiel);
    setFormName(referentiel.nom);
    setEditModal(true);
  };

  const handleCloseEditModal = () => {
    setEditModal(false);
    setFormName("");
    setCurrentReferentiel(null);
  };
  const validateForm = () => {
    const errors = {};
    if (!formName) {
      errors.formName = "Le nom du referentiel est requis";
    }
    console.log("selectedParent",selectedParent)
    if (!selectedParent && parentReferentiels.length) {
      errors.selectedParent = "Le referentiel parent est requis";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleFormSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      setLoading(true);
      const response = await api.post('/referentiels', {
        nom: formName,
        referentiel_parent_id: selectedParent?.value,
        category_id: idCategory
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      

    
      const newReferentiel = await response.data;
      console.log("newReferentiel",newReferentiel)
      setForms([...forms, newReferentiel]);
      setTriggerUpdate((prev) => !prev);

      handleCloseModal();
    } catch (error) {
      console.error("Error adding Referentiel:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditFormSubmit = async () => {
    try {
      setLoading(true);
      const response = await api.put(
        `/referentiels/${currentReferentiel.id}`,
        { nom: formName },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      

      const updatedReferentiel = await response.data;
      setForms(
        forms.map((form) =>
          form.id === updatedReferentiel.id ? updatedReferentiel : form
        )
      );
      handleCloseEditModal();
    } catch (error) {
      console.error("Error updating Referentiel:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (row) => {
    // Assuming `row.id` is the identifier for the row
    navigate(`/valeurreferentiel/${row.id}`);
  };
  const handleParentChange = (selectedOption) => {
    setSelectedParent(selectedOption);
  };

  useEffect(() => {
    api.get(`/referentiels?category_id=${idCategory}`)
      .then((response) => response.data)
      .then((data) => {
        setForms(data);
      //   const test = filteredReferentielsParents.filter(ref => ref.enfants )
      //   setFilteredReferentielsParents([...parentReferentiels, test]);
      // console.log("filtrednewReferentiel",test);
        setParentReferentiels(data);
        console.log("parentReferentiels",parentReferentiels);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [triggerUpdate]);
  const columns = [
    {
      name: "Nom",
      selector: (row) => row.nom,
    },
    {
      name: "Parent",
      selector: (row) =>
        row.parent ? (
          <>
            <TbCirclesRelation /> {row.parent.nom}
          </>
        ) : (
          ""
        ),
      sortable: true,
    },
    {
      name: "Date Creation",
      accessor: "created_at",
      selector: (row) => {
        // Format the created_at date
        const createdAtDate = new Date(row.created_at);
        return createdAtDate.toLocaleDateString(); // Adjust date format as needed
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <Button
          variant="link"
          onClick={() => handleShowEditModal(row)}
          style={{
            color: "#007bff",
          }}
        >
          <FaEdit
            style={{
              fontSize: "24px",
            }}
          />
        </Button>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "26px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "700",
        backgroundColor: "#fafafa ",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  return (
    <div>
      <div className="d-flex flex-column flex-root">
        {/* {/*begin::Page */}
        <div className="page d-flex flex-row flex-column-fluid">
          {/*begin::Wrapper */}
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            {/*begin::Header */}

            <Header />

            {/*end::Header */}
            <div className="Customcontainer bg-white">
              <div
                className="row bg-white"
                style={{ flex: 1, minHeight: "91.1vh" }}
              >
                <div
                  className="col-2 p-5 "
                  style={{ borderRight: "1px solid rgba(58,56,56,.1)" }}
                >
                  {/* Contenu de la colonne de gauche */}
                  <p
                    className="etech-sidebar-section"
                    style={{
                      color: "#3a3838",
                      fontWeight: "700",
                      padding: ".25em 0",
                    }}
                  >
                    Liste des Référentiels
                  </p>
                  <div className="d-flex align-items-center position-relative my-1">
                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <input
                      type="text"
                      data-kt-subscription-table-filter="search"
                      className="form-control form-control-solid w-250px ps-12"
                      placeholder="Recherche..."
                      onChange={handleInputChange}
                    />
                  </div>
                  <div class="d-grid gap-2 col-6 mx-auto">
                    <button
                      onClick={handleShowModal}
                      className="btn btn-primary"
                      disabled={loading}
                    >
                      Nouveau
                    </button>
                  </div>{" "}
                </div>
                <div className="col-10">
                  {/* Contenu de la colonne de droite */}
                  <h1
                    className="d-flex  fw-bold my-1 fs-3 p-4"
                    style={{ fontSize: "1.5em", fontWeight: "400" }}
                  >
                    Liste des Référentiels
                  </h1>
                  {/*begin::Card body */}
                  <div className="card-body pt-0">
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <LoadingIndicator />
                      </div>
                    ) : (
                      <>
                        {error ? (
                          "Data not Found"
                        ) : (
                          <DataTable
                            columns={columns}
                            data={filteredReferentiels}
                            pagination
                            highlightOnHover={true}
                            onRowClicked={handleRowClick}
                            paginationPerPage={10} // Set the number of rows per page
                            customStyles={customStyles}
                          />
                        )}
                      </>
                    )}
                  </div>

                  {/*end::Card body */}
                </div>
              </div>
            </div>
            {/* <Footer /> */}

            {/*end::Footer */}
          </div>
          {/*end::Wrapper */}
        </div>
        {/*end::Page */}
      </div>
      {/* Modal for adding new form */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un Referentiel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      <Form>
        <Form.Group controlId="formName">
          <Form.Label>Nom du Referentiel</Form.Label>
          <Form.Control
            type="text"
            value={formName}
            onChange={handleFormNameChange}
            isInvalid={!!formErrors.formName}
          />
          <Form.Control.Feedback type="invalid">
            {formErrors.formName}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formParent">
          <Form.Label>Referentiel Parent</Form.Label>
          <Select
            options={parentReferentiels.filter((referentiel) => referentiel.enfants.length === 0).map((referentiel) => ({
              value: referentiel.id,
              label: referentiel.nom,
            }))}
            value={selectedParent}
            onChange={handleParentChange}
            placeholder="Sélectionner un parent"
            className={formErrors.selectedParent ? "is-invalid" : ""}
          />
          {formErrors.selectedParent && (
            <div className="invalid-feedback">
              {formErrors.selectedParent}
            </div>
          )}
        </Form.Group>
       
      </Form>
    </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Annuler
          </Button>
          <Button
            variant="primary"
            onClick={handleFormSubmit}
            disabled={!formName || loading}
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Ajouter"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={editModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier le Referentiel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Nom du Referentiel</Form.Label>
              <Form.Control
                type="text"
                value={formName}
                onChange={handleFormNameChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Annuler
          </Button>
          <Button
            variant="primary"
            onClick={handleEditFormSubmit}
            disabled={loading || !formName}
          >
            {loading ? (
              <Spinner
                animation="border"
                style={{ width: "1rem", height: "1rem" }}
              />
            ) : (
              "Modifier"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ReferencielList;
